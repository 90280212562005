//Modules
import axios from 'axios'

//Variables
const API_URL = '/api/spaces/'
const { REACT_APP_COD_ESCOLA, REACT_APP_EVENTOS_EP, REACT_APP_SUMARIOS_API, REACT_APP_APIKEY, REACT_APP_SALAS_EP } = process.env

const getSpaces = async (userData) => {
    const config = {
        headers: {
            Authorization: `Bearer ${userData.token}`
        }
    }
    
    const response = await axios.get(`${API_URL}`, config)

    return response.data
}

const getSpace = async (spaceId, userData) => {
    const config = {
        headers: {
            Authorization: `Bearer ${userData.token}`
        }
    }
    const response = await axios.get(`${API_URL}${spaceId}`, config)

    return response.data
}

const createSpace = async (spaceData, user) => {
    const config = {
        headers: {
            Authorization: `Bearer ${user.token}`
        }
    }
    const response = await axios.post(API_URL, spaceData, config)

    return response.data
}

const updateSpace = async (spaceData, user) => {
    const config = {
        headers: {
            Authorization: `Bearer ${user.token}`
        }
    }
    const data = {
        name: spaceData.name,
        capacity: spaceData.capacity,
        codSala: spaceData.codSala,
        isVisible: spaceData.isVisible,
        hasClasses: spaceData.hasClasses
    }
    const response = await axios.put(`${API_URL}${spaceData.id}`, data, config)

    return response.data
}

const deleteSpace = async (spaceId, userData) => {
    const config = {
        headers: {
            Authorization: `Bearer ${userData.token}`
        }
    }
    const response = await axios.delete(`${API_URL}${spaceId}`, config)

    const finalData = {
        spaceId,
        message: response.data
    }

    return finalData
}

const getSpaceSumariosInfo = async () => {
    
    try {    
        const response = await axios.get(`${REACT_APP_SUMARIOS_API}${REACT_APP_EVENTOS_EP}${REACT_APP_SALAS_EP}${REACT_APP_COD_ESCOLA}?apikey=${REACT_APP_APIKEY}`)
        return response.data
    } catch (error) {
         return error
    }
 
 }

const spaceService = {
    getSpaces,
    getSpace,
    createSpace,
    getSpaceSumariosInfo,
    deleteSpace,
    updateSpace
}

export default spaceService