

const FormShowTutorial = ({ setIsChecked, handleTutorial, animation, isChecked, tutorialRef }) => {
  return (
    <section>
        <div className="fixed top-0 left-0 w-screen h-screen z-[999] bg-slate-600 opacity-60"></div>
        <div className="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[90%] md:w-3/4 lg:w-1/4 bg-white p-5 rounded-lg shadow-md z-[9999]">
            <h2 className='text-slate-600 mb-5 text-2xl text-center'>Tutorial</h2>
            <section className='w-4/5 m-auto mb-10 flex flex-col items-center'>
                <p className='text-slate-600 mb-5 text-lg text-center hidden md:block'>Clique e arraste o rato por quantos períodos pretender.</p>
                <p className='text-slate-600 mb-5 text-lg text-center block md:hidden'>Toque e segure o dedo por pelo menos 1 segundo, antes de arrastar por quantos períodos pretender.</p>
                <img src={animation} alt="Animação de como reservar" className='w-[40%]' />
                <p className='text-slate-600 mb-5 text-lg text-center block'>Em vista de mês clique no dia pretendido</p>
            </section>
            <section className='flex justify-between'>
                <label className='text-slate-600 text-md flex gap-2 mb-4'>
                <input
                    type="checkbox"
                    checked={isChecked}
                    ref={tutorialRef}
                    onChange={(e) => setIsChecked(e.target.checked)}
                />
                <span>Dispensar tutorial</span>
                </label>
                <button className='bg-esact-600 text-white border-none rounded-md px-5 py-2 text-sm cursor-pointer hover:bg-esact-900' onClick={handleTutorial}>Fechar</button>
            </section>
        </div>
    </section>
  )
}

export default FormShowTutorial