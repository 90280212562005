//Modules
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

//Utils
import { useLogout, isSessionActive } from '../utils/UtilFunctions'

//Components
import ListSpaceManager from '../components/spaces/ListSpaceManager'
import DashListUserReservations from '../components/reservations/DashListUserReservations'
import DashListSpaces from '../components/spaces/DashListSpaces'
import DashListReservationManager from '../components/reservations/DashListReservationManager'
import DashListReservationAdmin from '../components/reservations/DashListReservationAdmin'
import DashListWeekAdmin from '../components/reservations/DashListWeekAdmin'
import Spinner from '../components/Spinner'

function Dashboard() {
  const { user } = useSelector((state) => state.auth)

  const onLogout = useLogout()
  const active = isSessionActive()

  useEffect(() => {
    if(!active.sessionState){
      onLogout.out()
    }
  }, [])

  //LOADING
  if (!user) {
    return <Spinner />
  }

  return (
    <section className='px-5 md:px-0'>
      <section className='container  my-[40px]'>
        <h2 className='text-center text-3xl text-slate-600 font-bold mb-4'>DASHBOARD</h2>
      </section>
      {user.role.includes("root") && 
        <section className='container'>
          <h2 className='text-center text-xl text-slate-600 font-bold mb-2 border-b border-gray-300'>ADMINISTRADOR</h2>
          <section className='listWidgets'>
            <DashListReservationAdmin />
            <DashListWeekAdmin />
          </section>
        </section>}
      {user.role.includes("manager") && 
        <section className='container'>
          <h2 className='text-center text-xl text-slate-600 font-bold mb-2 border-b border-gray-300'>GESTOR</h2>
          <section className='listWidgets'>
            <ListSpaceManager />
            <DashListReservationManager />
          </section>
        </section>}
      <section className='container'>
        <h2 className='text-center text-xl text-slate-600 font-bold mb-2 border-b border-gray-300'>UTILIZADOR</h2>
        <section className='listWidgets'>
          <DashListUserReservations />
          <DashListSpaces />
        </section>
      </section>
    </section>
  )
}

export default Dashboard