//Modules
import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

//Components
import ListAllReservations from '../components/reservations/ListAllReservations'
import Spinner from '../components/Spinner'

//Utils
import reservationService from '../features/reservations/reservationService'
import { useLogout, isSessionActive } from '../utils/UtilFunctions'

const Reservations = () => {
    const [reservations, setReservations] = useState([])
    
    const onLogout = useLogout()
    const active = isSessionActive()

    const navigate = useNavigate()
    const { user } = useSelector((state) => state.auth)
    const { userReservations } = useSelector((state) => state.reservation)

    const getReservations = async () => {
        const data = await reservationService.getReservations(user)
        setReservations(data)
    }
  
    useEffect(() => {
        if(!active.sessionState){
            onLogout.out()
            toast.warning(active.message)
        }else if(user.role.includes("root")){
            getReservations()
        }else{
            setReservations(userReservations)
        }

    }, [user, navigate])

    //LOADING
    if (!user) {
        return <Spinner />
    }
  
    return (
        <section className='px-5 md:px-0 mb-14'>
            <section className='container  my-[40px]'>
                <h2 className='text-center text-3xl text-slate-600 font-bold mb-4 uppercase'>Reservas</h2>
            </section>

            <ListAllReservations reservations={reservations} user={user}/>

        </section>
    )
}

export default Reservations