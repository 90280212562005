//Module
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

//Components
import reservationService from './reservationService'

//Variables
const initialState = {
    userReservations: [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: ''
}

export const changeUserReservationState = createAsyncThunk('reservation/changeUserReservationState', async (newUserReservations, thunkAPI) => {
    try {   
        return newUserReservations
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

export const getUserReservations = createAsyncThunk('reservation/getUserReservations', async (user, thunkAPI) => {
    try {   
        return await reservationService.getUserReservations(user)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

export const getSpaceReservations = createAsyncThunk('reservation/getSpaceReservations', async (spaceData, thunkAPI) => {
    try {   
        return await reservationService.getSpaceReservations(spaceData,thunkAPI.getState().auth.user)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

export const setReservation = createAsyncThunk('reservation/setReservation', async (reservationData, thunkAPI) => {
    try {   
        return await reservationService.setReservation(reservationData,thunkAPI.getState().auth.user)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

export const updateReservation = createAsyncThunk('reservation/updateReservation', async (reservationData, thunkAPI) => {
    try {   
        return await reservationService.updateReservation(reservationData,thunkAPI.getState().auth.user)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

export const deleteReservation = createAsyncThunk('reservation/deleteReservation', async (reservationID, thunkAPI) => {
    try {   
        return await reservationService.deleteReservation(reservationID,thunkAPI.getState().auth.user)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

export const reservationSlice = createSlice({
    name: 'reservation',
    initialState,
    reducers: {
        reservationsReset: (state) => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(getUserReservations.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getUserReservations.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
                state.userReservations = action.payload
            })
            .addCase(getUserReservations.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(getSpaceReservations.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getSpaceReservations.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
            })
            .addCase(getSpaceReservations.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(setReservation.pending, (state) => {
                state.isLoading = true
            })
            .addCase(setReservation.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
            })
            .addCase(setReservation.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(updateReservation.pending, (state) => {
                state.isLoading = true
            })
            .addCase(updateReservation.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
                // state.reservation = null
            })
            .addCase(updateReservation.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(deleteReservation.pending, (state) => {
                state.isLoading = true
            })
            .addCase(deleteReservation.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
                // state.userReservations = action.payload
            })
            .addCase(deleteReservation.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(changeUserReservationState.pending, (state) => {
                state.isLoading = true
            })
            .addCase(changeUserReservationState.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
                state.userReservations = action.payload
            })
            .addCase(changeUserReservationState.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
    }
})

export const { reservationsReset, reservationReset } = reservationSlice.actions
export default reservationSlice.reducer