//Moudules
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import jwtDecode from 'jwt-decode'

//Utils
import { logout, reset } from "../features/auth/authSlice";
import { spacesReset } from '../features/spaces/spaceSlice'
import { managerSpacesReset } from '../features/managers/managerSlice'
import { reservationsReset } from '../features/reservations/reservationSlice'

export function GetCurrentWeek() {
    const today = new Date();
    const dayOfWeek = today.getDay();
    const firstDayOfWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - dayOfWeek +1);
    const lastDayOfWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - dayOfWeek + 7);

    return {start: firstDayOfWeek, end: lastDayOfWeek}
}

export const useLogout = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    
    const out = () => {        
        dispatch(reset())
        dispatch(managerSpacesReset())
        dispatch(spacesReset())
        dispatch(reservationsReset())
        dispatch(logout())    
        navigate('/login')
    }

    return {out}
}

export const isSessionActive = () => {
    let sessionState = false
    let message = "Por favor faça login para aceder a este recurso."
    const user = JSON.parse(localStorage.getItem('user'))

    if (user) {
        const decodedToken = jwtDecode(user.token)
        const currentTime = Date.now() / 1000
        sessionState = decodedToken.exp > currentTime
        if(!sessionState) {
            message = "Sessão expirada."
        }else{
            message = ""
        }
    }

    return {sessionState,message}
}