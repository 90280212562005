//Modules
import { useEffect, useState } from 'react'
import { FaSignInAlt, FaSignOutAlt, FaHome, FaUserSecret } from 'react-icons/fa'
import { BiUserCircle } from  'react-icons/bi'
import { MdOutlineSupervisedUserCircle } from 'react-icons/md'
import { FiMenu } from 'react-icons/fi'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

//Utils
import { getPhoto } from '../features/auth/authSlice'
import { getSpaces } from '../features/spaces/spaceSlice'
import { getUserReservations } from '../features/reservations/reservationSlice'
import { getManagerSpaces } from '../features/managers/managerSlice'
import { useWindowWidthContext } from '../utils/UtilContext'
import { useLogout } from '../utils/UtilFunctions'

//Components
import Spinner from '../components/Spinner'

function Header() {
  const [isLoading, setIsLoading] = useState(true)

  const { windowWidth } = useWindowWidthContext()
  const onLogout = useLogout()

  const dispatch = useDispatch()

  const { user, photo } = useSelector((state) => state.auth)
  const { spaces } = useSelector((state) => state.space)
  const { userReservations } = useSelector((state) => state.reservation)
  const { managerSpaces } = useSelector((state) => state.manager)

  useEffect(() => {
    if (user) {
      spaces.length === 0 && dispatch(getSpaces(user))
      userReservations.length === 0 && dispatch(getUserReservations(user))
      user.role.includes("manager") && managerSpaces.length === 0 && dispatch(getManagerSpaces(user))
    }

  }, [user,dispatch])

  useEffect(() => {
    if (user) {
      if (user.email.includes('@ipb.pt') || user.email.includes('@alunos.ipb.pt')){        
        const gettingPhoto = dispatch(getPhoto(user.photoToken))
        gettingPhoto.then(() => {
          setIsLoading(false)
        })
      }else{
        setIsLoading(false)
      }
    }else{
      setIsLoading(false)
    }
  },[user,dispatch])

  //LOADING
  if (isLoading) {
    return <Spinner />
  }

  return (
    <header className='flex justify-between items-center py-5 border-solid border-b-[1px] border-gray-200 px-5 md:px-0'>
        <div className=" flex gap-3 items-start">
            {user && <Link to='/' className='text-xl md:text-3xl hover:text-gray-600'><FaHome /></Link>}
            <Link className='font-bold text-l text-esact-600 md:text-2xl' to='/'>RESERVA DE SALAS</Link>
        </div>
        <ul className='flex gap-5 items-center justify-between'>
          {user ? 
          (<>         
            <li className='flex gap-2 items-center text-gray-600'>
                {(user.role.includes("root") || user.role.includes("admin")) ? <div className={photo === '' ? 'border-[3px] border-gray-600 rounded-full pt-[6px] px-1 ' : ''}>
                    {photo === '' ? <FaUserSecret className='text-2xl' /> : <div style={{backgroundImage: `url(${photo})`}} className='w-12 h-12 bg-no-repeat bg-center bg-cover rounded-full border-[3px] border-[#dc2f2f] '></div>}
                  </div> : (user.role.includes("manager") ? 
                    (photo === '' ? <MdOutlineSupervisedUserCircle className='text-4xl' /> : <div style={{backgroundImage: `url(${photo})`}} className='w-12 h-12 bg-no-repeat bg-center bg-cover rounded-full border-[3px] border-[#0092ca] '></div>) : 
                    (photo === '' ? <BiUserCircle className='text-4xl' /> : <div style={{backgroundImage: `url(${photo})`}} className='w-12 h-12 bg-no-repeat bg-center bg-cover rounded-full border-[3px] border-esact-600 '></div>))}
                {windowWidth > 768 ? 
                  <span>{user.name}</span> : 
                  <span className='font-bold'>{user.name.split(' ')[0].charAt(0)+'.'+user.name.split(' ')[user.name.split(' ').length-1].charAt(0)+'.'}</span>
                }
              </li>       
            <li className="dropdown relative">
              <button 
                className='dropdown-toggle text-slate-600 font-bold text-base transition duration-150 ease-in-out flex items-center gap-2 whitespace-nowrap'
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <FiMenu className='text-4xl' />
              </button>
              <ul
                className='dropdown-menu w-40 md:w-48 absolute bg-white text-base z-50 float-left py-2 px-2 list-none text-left rounded-lg shadow-lg mt-1 hidden m-0 bg-clip-padding border-none'
                aria-labelledby="dropdownMenuButton1"
              >
                {user.role.includes("root") &&
                <>
                  <li className='mb-2'>
                    <Link to='/spaces' className='dropdown-item text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-gray-100'>
                        Salas
                    </Link>
                  </li>
                  <li className='mb-2'>
                    <Link to='/users' className='dropdown-item text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-gray-100'>
                        Utilizadores
                    </Link>
                  </li>                                    
                </>}  
                <li className='mb-2'>
                    <Link to='/reservations' className='dropdown-item text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-gray-100'>
                        Reservas
                    </Link>
                  </li>     
                <li className='mb-2'>
                    <Link to='/profile' className='dropdown-item text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-gray-100'>
                        Perfil
                    </Link>
                  </li>     
                <li className='mt-4'>
                  <button 
                    className='py-1 px-3 w-full border border-solid border-esact-600 rounded-md bg-esact-600 text-white text-base font-bold cursor-pointer text-center flex items-center justify-center gap-2'
                    onClick={onLogout.out}>
                    <FaSignOutAlt /> <span>Logout</span>
                  </button>
                </li>                  
              </ul>
            </li>            
          </>
          ) 
          : 
          (<>
            <li className='ml-5'><Link to='/login' className='flex items-center gap-1 hover:text-gray-600'><FaSignInAlt /> Login</Link></li>            
          </>)}
          
        </ul>
    </header>
  )
}

export default Header