//Modules
import { Fragment, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { GrEdit } from 'react-icons/gr'
import { MdDelete } from 'react-icons/md'
import { FiCheckCircle } from 'react-icons/fi'
import { TiDeleteOutline } from 'react-icons/ti'
import { AiOutlineCloseCircle, AiOutlineCalendar } from 'react-icons/ai'
import { Dialog, Transition } from '@headlessui/react'
import { useConfirm } from 'react-confirm-window'

//Components
import { deleteSpace, updateSpace } from '../../features/spaces/spaceSlice'
import FormUpdateSpace from './FormUpdateSpace'
import reservationService from '../../features/reservations/reservationService'


function DashSpace({ space, windowWidth, pathname, user, index }) {
  let [isOpen, setIsOpen] = useState(false)
  const dispatch = useDispatch()
  const confirm = useConfirm()

  function closeModal() {
    setIsOpen(false)
  }
  function openModal() {
    setIsOpen(true)
  }
  
  const onDelete = async () => {
    const choice = await confirm({
      title: "Tem a certeza que deseja apagar a sala?",
      closeButtonLable: "Não",
      confirmButtonLable: "Sim",
    })

    if (choice) {
      const spaceReservations = await reservationService.getSpaceReservations({spaceId:space._id},user)

      if (spaceReservations.length === 0){        
        const result = dispatch(deleteSpace(space._id))
        result.then((data) => {
          if(data.error){
              toast.error(data.payload)
          }else{
              toast.success(data.payload.message)
          }
        })
      }else{
        const spaceData = {
          id: space._id,
          name: space.name,
          capacity: space.capacity,
          codSala: space.codSala,
          isVisible: false,
          hasClasses: space.hasClasses
      }
        const result = dispatch(updateSpace(spaceData))
        result.then((data) => {
            if(data.error){
                toast.error(data.payload)
            }else{
                toast.success(data.payload.message)                
            }
        })
      }
    }
  }

  return (
    <>
      {((space.isVisible && user.role.some(value => space.role.includes(value))) || (pathname === '/spaces' && user.role.includes("root")) ) &&
        <tr className='text-gray-600'>
          <td>{index+1}</td>
          {user.role.includes("root") ? 
          <td><Link to={`/spaces/${space._id}`} className='underline'>{space.name}</Link></td>            
          :<td>{space.name}</td>
          }
          {windowWidth > 640 && <td className='text-center'>{space.capacity === 0 ? "n/d" : space.capacity}</td>}
          {(windowWidth > 640 || pathname !== '/spaces') && <td className='flex justify-center'><Link to={`/space/${space._id}`}><AiOutlineCalendar className='text-esact-600 text-2xl' /></Link></td>}
          {pathname === '/spaces' && user.role.includes("root") && 
            <>              
              <td>
                <span className='flex justify-center gap-3 md:gap-8'>
                  <GrEdit onClick={openModal} className='text-xl cursor-pointer' />
                  <MdDelete onClick={onDelete} className='text-xl cursor-pointer text-rejected' />
                </span>
                <Transition appear show={isOpen} as={Fragment}>
                  <Dialog as="div" className="relative z-10" onClose={closeModal} >
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                      <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                          as={Fragment}
                          enter="ease-out duration-300"
                          enterFrom="opacity-0 scale-95"
                          enterTo="opacity-100 scale-100"
                          leave="ease-in duration-200"
                          leaveFrom="opacity-100 scale-100"
                          leaveTo="opacity-0 scale-95"
                        >
                          <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                            <Dialog.Title
                              as="h3"
                              className=" relative text-lg font-medium leading-6 text-gray-900 uppercase text-center mb-10"
                            >
                              Alterar Sala
                              <span onClick={closeModal} className='absolute text-2xl cursor-pointer text-gray-500 top-2 right-2'><AiOutlineCloseCircle /></span>
                            </Dialog.Title>                  
                            <FormUpdateSpace closeModal={closeModal} space={space} />                  
                          </Dialog.Panel>
                        </Transition.Child>
                      </div>
                    </div>
                  </Dialog>
                </Transition>
              </td>
              <td className='flex justify-center'>{space.isVisible ? <FiCheckCircle className='text-lg text-esact-900' /> : <TiDeleteOutline className='text-2xl text-rejected' />}</td>
            </>}
        </tr>}
        
        
    </>
  )
}

export default DashSpace