//Module
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

//Components
import spaceService from './spaceService'

//Variables
const initialState = {
    spaces: [],
    space: null,
    isError: false,
    isErrorSpaces: false,
    isSuccess: false,
    isLoading: false,
    message: ''
}

export const getSpaces = createAsyncThunk('space/getSpaces', async (user,thunkAPI) => {      
    try {                
        return await spaceService.getSpaces(user)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

export const getSpace = createAsyncThunk('space/getSpace', async (spaceId,thunkAPI) => {
    try {                
        return await spaceService.getSpace(spaceId,thunkAPI.getState().auth.user)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

export const createSpace = createAsyncThunk('space/createSpace', async (space,thunkAPI) => {      
    try {                
        return await spaceService.createSpace(space,thunkAPI.getState().auth.user)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

export const updateSpace = createAsyncThunk('space/updateSpace', async (spaceData,thunkAPI) => {      
    try {                
        return await spaceService.updateSpace(spaceData,thunkAPI.getState().auth.user)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

export const deleteSpace = createAsyncThunk('space/deleteSpace', async (spaceId,thunkAPI) => {
    try {                
        return await spaceService.deleteSpace(spaceId,thunkAPI.getState().auth.user)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

export const spaceSlice = createSlice({
    name: 'space',
    initialState,
    reducers: {
        spacesReset: (state) => initialState,
        spaceReset: (state) => {
            state.space = null
            state.isLoading = false
            state.isError = false
            state.isSuccess = false
            state.message = ''
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getSpaces.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getSpaces.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
                state.isErrorSpaces = false
                state.spaces = action.payload
            })
            .addCase(getSpaces.rejected, (state, action) => {
                state.isLoading = false
                state.isErrorSpaces = true
                state.isSuccess = false
                state.message = action.payload
            })
            .addCase(getSpace.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getSpace.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
                state.space = action.payload
            })
            .addCase(getSpace.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.isSuccess = false
                state.message = action.payload
            })
            .addCase(createSpace.pending, (state) => {
                state.isLoading = true
            })
            .addCase(createSpace.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
                state.spaces = [...state.spaces, action.payload.newSpace]
            })
            .addCase(createSpace.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.isSuccess = false
                state.message = action.payload
            })
            .addCase(deleteSpace.pending, (state) => {
                state.isLoading = true
            })
            .addCase(deleteSpace.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
                state.spaces = state.spaces.filter((space) => space._id !== action.payload.spaceId)
            })
            .addCase(deleteSpace.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.isSuccess = false
                state.message = action.payload.message
            })
            .addCase(updateSpace.pending, (state) => {
                state.isLoading = true
            })
            .addCase(updateSpace.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
                state.spaces = [...state.spaces.filter((space) => space._id !== action.payload.updatedSpace._id), action.payload.updatedSpace]
            })
            .addCase(updateSpace.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.isSuccess = false
                state.message = action.payload.message
            })            
    }
})

export const { spacesReset, spaceReset } = spaceSlice.actions
export default spaceSlice.reducer