//Modules
import {useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { FaEye } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import { formatDate } from '@fullcalendar/core'

//Utils
import authService from '../../features/auth/authService'
import spaceService from '../../features/spaces/spaceService'
import { useWindowWidthContext } from '../../utils/UtilContext'

//Components
import Spinner from '../Spinner'

function DashReservationManagerPending({ reservation, index }) {
    const [reservationUserData, setReservationUserData] = useState({})
    const [reservationSpaceData, setReservationSpaceData] = useState({})

    const { windowWidth } = useWindowWidthContext()
    
    const { user } = useSelector((state) => state.auth)

    const getUserData = async () => {
        const userInfo = await authService.getUser(reservation.user_id,user)
        setReservationUserData(userInfo)
    }

    const getSpaceData = async () => {
        const spaceInfo = await spaceService.getSpace(reservation.space_id,user)
        setReservationSpaceData(spaceInfo)
    }

    useEffect(() => {           
        getUserData()
        getSpaceData()
    }, [])

    if (!reservationUserData || !reservationSpaceData) {
    return <tr><td><Spinner /></td></tr>
    }

    return (
        <tr className='text-gray-600'>
            <td>{index+1}</td>
            <td>{reservationSpaceData.name}</td>
            {windowWidth > 640 && <td className='p-2'>{reservationUserData.name}</td>}
            <td className='text-center'>{formatDate(reservation.begin, {day:'2-digit', month: '2-digit', year: 'numeric', locale: 'pt'})}</td>
            {windowWidth > 640 && 
            <>
                <td className='text-center'>{formatDate(reservation.begin, {hour:'2-digit', minute: '2-digit', timeZone: 'Europe/lisbon', locale: 'pt'})}</td>
                <td className='text-center'>{formatDate(reservation.end, {hour:'2-digit', minute: '2-digit', timeZone: 'Europe/lisbon', locale: 'pt'})}</td>
            </>}
            <td className='text-center'><Link to={`/space/${reservation.space_id}/${reservation.begin.slice(0,10)}`}><FaEye className='text-pending text-2xl' /></Link></td>
        </tr>
    )
}

export default DashReservationManagerPending