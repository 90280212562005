//Modules
import {useEffect, useState} from 'react'
import { useSelector } from 'react-redux'

//Utils
import { GetCurrentWeek } from '../../utils/UtilFunctions'
import reservationService from '../../features/reservations/reservationService'

//Components
import DashWeekAdminAll from './DashWeekAdminAll'
import Spinner from '../Spinner'

const DashWeekAdmin = ({ space }) => {
    const [spaceWeekReservations, setSpaceWeekReservations] = useState([])  
    const week = GetCurrentWeek()

    const { user } = useSelector((state) => state.auth)
    
    const getReservationData = async () => {
        const reservationInfo = await reservationService.getSpaceReservations({spaceId: space._id, start: week.start, end: week.end},user)
        setSpaceWeekReservations(reservationInfo)
    }

    useEffect(() => {           
        getReservationData()
      }, [])
    
    if (!spaceWeekReservations) {
    return <tr><td><Spinner /></td></tr>
    }
    
    return (
        <>
            {spaceWeekReservations.map((reservation, index) => (
                <DashWeekAdminAll key={reservation._id} reservation={reservation} index={index} />
            ))}
        </>
  )
}

export default DashWeekAdmin