//Modules
import { useEffect, useState} from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { FaEye } from 'react-icons/fa'
import { formatDate } from '@fullcalendar/core'

//Utils
import spaceService from '../../features/spaces/spaceService'

//Components
import Spinner from '../Spinner'

//Variables
const reservationState = ['Cancelada', 'Pendente', 'Aprovada']
const reservationColor = ['text-rejected', 'text-pending', 'text-aproved']

function DashUserReservations({ userReservation, windowWidth, index }) {
    const [space, setSpace] = useState()
  
    const { user } = useSelector((state) => state.auth)
    
    const getSpace = async () => {
        const spaceInfo = await spaceService.getSpace(userReservation.space_id,user)
        setSpace(spaceInfo)
    }

    useEffect(() => {           
        getSpace()
      }, [])
    
      if (!space) {
        return <tr><td><Spinner /></td></tr>
      }

    return (
        <tr className='text-gray-600'>
            <td>{index+1}</td>
            <td>{space.name}</td>
            <td className='text-center'>{formatDate(userReservation.begin, {day:'2-digit', month: '2-digit', year: 'numeric', locale: 'pt'})}</td>
            {windowWidth > 640 && 
            <>
                <td className='text-center'>{formatDate(userReservation.begin, {hour:'2-digit', minute: '2-digit', timeZone: 'Europe/lisbon', locale: 'pt'})}</td>
                <td className='text-center'>{formatDate(userReservation.end, {hour:'2-digit', minute: '2-digit', timeZone: 'Europe/lisbon', locale: 'pt'})}</td>
            </>}
            <td className={`text-center ${reservationColor[userReservation.state]}`}>{reservationState[userReservation.state]}</td>
            <td className='text-center'><Link to={`/space/${space._id}/${userReservation.begin.slice(0,10)}`}><FaEye className={`text-2xl ${reservationColor[userReservation.state]}`} /></Link></td>
        </tr>
    )
}

export default DashUserReservations