//Modules
import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Switch } from '@headlessui/react'
import { FaUser } from 'react-icons/fa'
import { GrEdit } from 'react-icons/gr'
import { toast } from 'react-toastify'

//Components
import ListSpaceManager from '../components/spaces/ListSpaceManager'
import Spinner from '../components/Spinner'

//Utils
import { updateUser } from '../features/auth/authSlice'
import { useLogout, isSessionActive } from '../utils/UtilFunctions'

const Profile = () => {
    const [enabled, setEnabled] = useState(false)
    const [showOfficeEdit, setShowOfficeEdit] = useState(false)
    const [officeFields, setOfficeFields] = useState({
        office: '',
        phone: ''
    })

    
    const { office, phone } = officeFields
    
    const dispatch = useDispatch()
    
    const { user, photo } = useSelector((state) => state.auth)
    
    const onLogout = useLogout()
    const active = isSessionActive()
    
    const changeActive = (val) => {
        setEnabled(val)
        
        let dataToUpdate = []
        dataToUpdate.push({tutorial: val}, user)
        
        const active = dispatch(updateUser(dataToUpdate, user))
        active.then((t) => {
            toast.success(val === true ? 'Tutorial ativado' : 'Tutorial desativado')
        })
    }
    
    const onChangeOffice = (e) => {                  
        setOfficeFields((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }
    
    const onSubmitOffice = (e) => {
        e.preventDefault()
        
        let dataToUpdate = []
        dataToUpdate.push({
            office,
            phone
        }, user)
        
        const active = dispatch(updateUser(dataToUpdate, user))
        active.then((t) => {
            toast.success('Dados alterados.')
            setShowOfficeEdit(false)
        })
    }
    

    useEffect(() => {
        if(!active.sessionState){
            onLogout.out()
            toast.warning(active.message)
        }else{            
            setEnabled(user.tutorial)
            setOfficeFields({
                office: user.office,
                phone: user.phone
            })
        }
    },[user])

    //LOADING
    if (!user) {
        return <Spinner />
    }
    
    return (
        <section className='px-5 md:px-0'>        
            <div className='mt-5 w-full rounded-xl shadow-custom1 bg-white p-4 flex justify-between'>
                <div className='flex gap-5 items-center'>
                    {photo === '' ? <FaUser className='text-6xl' /> : <div style={{backgroundImage: `url(${photo})`}} className='w-20 h-20 bg-no-repeat bg-center bg-cover rounded-2xl shadow-lg'></div>}
                    <div className='flex flex-col'>
                        <h2 className='text-md md:text-xl text-gray-700'>{user.name}</h2>
                        <p className='text-sm md:text-md text-gray-500'>{user.email}</p>
                    </div>
                </div>                
            </div>

            <section className='container'>
                <section className='listWidgets'>
                    <section className='tableList'>
                        <div className='profileTitle'>Definições</div>   
                        <div className='px-7 py-4'>
                            <div className='flex flex-col gap-4'>
                                <span className='profileSubTitle'>Tutorial</span>
                                <div className='flex gap-3 items-center'>
                                    <Switch
                                        checked={enabled}
                                        onChange={changeActive}
                                        className={`${enabled ? 'bg-aproved' : 'bg-rejected'}
                                        relative inline-flex h-[25px] w-[57px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                                    >
                                        <span className="sr-only">Use setting</span>
                                        <span
                                        aria-hidden="true"
                                        className={`${enabled ? 'translate-x-8' : 'translate-x-0'}
                                            pointer-events-none inline-block h-[21px] w-[21px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                                        />
                                    </Switch>
                                    <span className='text-sm text-gray-600'>Ativa/Desativa o tutorial de como fazer uma reserva.</span>
                                </div>
                            </div>

                        </div>                         
                    </section>

                    <section className='tableList'>
                        <div className='profileTitle flex justify-between'>
                            <span>Informações</span>
                        </div>      
                        <div className='px-7 py-4'>
                            <div className='flex flex-col gap-3'>
                                <div className='flex gap-5 items-start'>
                                    <span className='profileSubTitle'>gabinete</span>
                                    <GrEdit className='cursor-pointer' onClick={() => setShowOfficeEdit(true)} />
                                </div>
                                <form className='flex flex-col gap-1' onSubmit={onSubmitOffice}>
                                    <div className='flex gap-3 items-center'>
                                        <span className='text-sm font-bold text-gray-600'>Porta:</span>
                                        {!showOfficeEdit && <span className='text-sm text-gray-600 py-2'>{user.office}</span>}
                                        {showOfficeEdit && <input type='text' id='office' name='office' value={office} onChange={onChangeOffice} className='w-full text-sm py-2 px-3 rounded-lg shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-esact-600' />}
                                    </div>
                                    <div className='flex gap-3 items-center'>
                                        <span className='text-sm font-bold text-gray-600'>Extensão:</span>
                                        {!showOfficeEdit && <span className='text-sm text-gray-600 py-2'>{user.phone}</span>}
                                        {showOfficeEdit && <input type='text' id='phone' name='phone' value={phone} onChange={onChangeOffice} className='w-full text-sm py-2 px-3 rounded-lg shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-esact-600' />}
                                    </div>
                                    {showOfficeEdit &&
                                        <div className='mb-3 flex gap-4'>
                                            <button type='submit' className='grow mb-5 py-3 px-5 border border-solid border-esact-600 rounded-md bg-esact-600 text-white text-base font-bold cursor-pointer text-center flex items-center justify-center'>Guardar</button>
                                            <button type='button' onClick={() => setShowOfficeEdit(false)} className='grow mb-5 py-3 px-5 border border-solid border-esact-600 rounded-md bg-esact-600 text-white text-base font-bold cursor-pointer text-center flex items-center justify-center'>Cancelar</button>
                                        </div>
                                    }
                                </form>
                            </div>
                        </div>                   
                    </section>
                    
                    {user.role.includes("manager") && <ListSpaceManager />}
                    
                </section>
            </section>
        
        </section>
    )
}

export default Profile