//Modules
import { useSelector } from 'react-redux'

//Components
import ManagerSpace from './ManagerSpace'

//Utils
import { useWindowWidthContext } from '../../utils/UtilContext'

const ListManagersSpace = ({ managers, spaceId, setSpaceManagers }) => {
    const { windowWidth } = useWindowWidthContext()

    const { user } = useSelector((state) => state.auth)

  return (
    <section className='tableList'>
        <div className='tableListTitle'>Gestores</div>
        <table className='w-full'>
            <thead>
                <tr>
                    <td>#</td>
                    <td></td>
                    {windowWidth > 640 && <td>Nome</td>}
                    <td>email</td>
                    <td className='text-center'>Extensão</td>
                    {windowWidth > 640 && <td className='text-center'>Sala</td>}                    
                    {windowWidth > 640 && <td className='text-center'>Ativo</td>}
                    <td className='text-center'></td>
                </tr>
            </thead> 
            <tbody>
                {managers.map((manager, index) => (
                    <ManagerSpace key={manager._id} manager={manager} windowWidth={windowWidth} spaceId={spaceId} user={user} index={index} setSpaceManagers={setSpaceManagers} managers={managers} />
                ))}
            </tbody>
        </table>      
    </section>
  )
}

export default ListManagersSpace