//Modules
import { useSelector } from 'react-redux'

//Utils
import { useWindowWidthContext } from '../../utils/UtilContext'

//Components
import DashReservationManager from './DashReservationManager'
import Spinner from '../Spinner'

function DashListReservationManager() {
    const { managerSpaces, isLoading } = useSelector((state) => state.manager)
    const { windowWidth } = useWindowWidthContext()
    
    if (isLoading) {
        return <Spinner />
    }

    return (
        <section className='tableList'>
            <div className='tableListTitle'>Reservas Pendentes</div>
            <table className='w-full'>
                <thead>
                    <tr>
                        <td>#</td>
                        <td>Sala</td>
                        {windowWidth > 640 && <td>Utilizador</td>}
                        <td className='text-center'>Dia</td>                        
                        {windowWidth > 640 && 
                        <>
                            <td className='text-center'>Início</td>
                            <td className='text-center'>Fim</td>
                        </>}
                        <td></td>
                    </tr>
                </thead>
                <tbody>
                {managerSpaces.map((managerSpace) => (                    
                    <DashReservationManager key={managerSpace._id} managerSpace={managerSpace} />
                ))}
                </tbody>
            </table>      
        </section>
    )
}

export default DashListReservationManager