//Modules
import { useState, useEffect } from 'react'
import { FaSignInAlt } from 'react-icons/fa'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

//components
import Spinner from '../components/Spinner'
import { login } from '../features/auth/authSlice'

function Login() {
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  })

  const { email, password } = formData

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { user, isLoading, isSuccess, isError, message } = useSelector((state) => state.auth)

  useEffect(() => {
    if(isError){
      toast.error(message)
    }
    if(isSuccess || user){
      navigate('/')
    }
  }, [user, isError, isSuccess, message, navigate, dispatch])

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }))
  }

  const onSubmit = (e) => {
    e.preventDefault()

    const userData = {
      email,
      password
    }

    dispatch(login(userData))
  }

  if(isLoading){
    return <Spinner />
  }

  return (
    <>
      <section className='mb-12 px-5 font-bold text-center'>
        <h1 className='flex items-center justify-center mb-5 gap-2 text-[3rem] text-esact-600'><FaSignInAlt /> Login</h1>
        <p className='text-[1.5rem] md:text-[2rem] text-gray-600'>Faça login com a sua conta</p>
      </section>

      <section className='w-9/12 mx-auto'>
        <form onSubmit={onSubmit}>          
          <div className='mb-3'>            
            <input id='email' name='email' value={email} placeholder='Escreva o email' onChange={onChange} className='w-full p-3 border border-solid border-gray-600 rounded-md mb-3' />
          </div>
          <div className='mb-3'>            
            <input type='password' id='password' name='password' value={password} placeholder='Escreva a password' onChange={onChange} className='w-full p-3 border border-solid border-gray-600 rounded-md mb-3' />
          </div>
          <div className='mb-3'>
            <button type='submit' className='w-full mb-5 py-3 px-5 border border-solid border-esact-600 rounded-md bg-esact-600 text-white text-base font-bold cursor-pointer text-center flex items-center justify-center'>Submeter</button>
          </div>
        </form>
      </section>
    </>
  )
}

export default Login