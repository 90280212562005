//Modules
import {useEffect, useState} from 'react'
import { useSelector } from 'react-redux'

//Utils
import reservationService from '../../features/reservations/reservationService'

//Components
import Spinner from '../Spinner'
import DashReservationAdminPending from './DashReservationAdminPending'

function DashReservationAdmin({ space }) {
    const [spaceAdminReservations, setSpaceAdminReservations] = useState([])  
    const today = new Date()  

    const { user } = useSelector((state) => state.auth)
    
    const getReservationData = async () => {
        const reservationInfo = await reservationService.getSpaceReservations({spaceId: space._id, start: today, state: 1},user)
        setSpaceAdminReservations(reservationInfo)
    }

    useEffect(() => {           
        getReservationData()
      }, [])
    
    if (!spaceAdminReservations) {
    return <tr><td><Spinner /></td></tr>
    }
    
    return (
        <>
            {spaceAdminReservations.map((reservation, index) => (
                <DashReservationAdminPending key={reservation._id} reservation={reservation} index={index} />
            ))}
        </>
    )
}

export default DashReservationAdmin