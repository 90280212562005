//Modules
import { useState, useEffect, Fragment } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { Combobox, Transition } from '@headlessui/react'
import { AiOutlineCheckCircle } from 'react-icons/ai'
import { HiOutlineSelector } from 'react-icons/hi'

//components
import Spinner from '../../components/Spinner'
import { createSpace } from '../../features/spaces/spaceSlice'
import spaceService from '../../features/spaces/spaceService'

const FormAddSpace = ({ closeModal }) => {
    const [addSpaceFields, setAddSpaceFields] = useState({
        name: '',
        capacity: '',
        codSala: '',        
        isVisible: true,    
        hasClasses: true
    })
    const [codSalas, setCodSalas] = useState([])
    const [selectedSpace, setSelectedSpace] = useState({})
    const [query, setQuery] = useState('')

    const { name, capacity, codSala, isVisible, hasClasses } = addSpaceFields

    const dispatch = useDispatch()

    const { isLoading } = useSelector((state) => state.space)

    const getSpacesInfo = async () => {
        const data = await spaceService.getSpaceSumariosInfo()
        setCodSalas(data)
    }

    useEffect(() => {
        getSpacesInfo()
    }, [])

    const onChange = (e) => {
        if ('codSala' in e){            
            setSelectedSpace(e)
            setAddSpaceFields((prevState) => ({
                ...prevState,
                codSala: e.codSala
            }))
        }else{            
            setAddSpaceFields((prevState) => ({
                ...prevState,
                [e.target.name]: (e.target.name === ('isVisible') || e.target.name === ('hasClasses')) ? e.target.checked : e.target.value
            }))
        }
    }

    const onSubmit = (e) => {
        e.preventDefault()
        
        const spaceData = {
            name,
            capacity,
            codSala,
            isVisible,
            hasClasses
        }

        const result = dispatch(createSpace(spaceData))
        result.then((data) => {
            if(data.error){
                toast.error(data.payload)
            }else{
                toast.success(data.payload.message)
                setAddSpaceFields({
                    name: '',
                    capacity: '',
                    codSala: '',
                    isVisible: true,
                    hasClasses: true
                })
                setSelectedSpace({})
                closeModal()
            }
        })
    }

    const filteredSpace =
    query === ''
      ? codSalas
      : codSalas.filter((sala) => {
          return sala.codSala.toLowerCase().includes(query.toLowerCase())
        })
    
    if(isLoading || codSalas.length === 0){
        return <Spinner />
      }

    return (
        <>
        <section className='w-full mx-auto'>
            <form onSubmit={onSubmit}>
                <div className='mb-5'>     
                    <label>Nome:</label>      
                    <input type='text' id='name' name='name' value={name} placeholder='Escreva o nome da sala' onChange={onChange} className='w-full p-3 rounded-lg shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-esact-600' />
                </div>
                <div className='mb-5'>     
                <label>Capacidade:</label>      
                    <input type='text' id='capacity' name='capacity' value={capacity} placeholder='Digite a capacidade da sala' onChange={onChange} className='w-full p-3 rounded-lg shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-esact-600' />
                </div>
                <div className='mb-5'>     
                    <label>Código:</label>      
                    <Combobox name='codSala' value={selectedSpace} onChange={onChange}>
                        <div className="relative mt-1">
                        <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-esact-600 sm:text-sm">
                            <Combobox.Input
                            className="w-full border-none p-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                            displayValue={(sala) => sala.codSala}
                            onChange={(event) => setQuery(event.target.value)}
                            />
                            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                                <HiOutlineSelector
                                    className="h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                />
                            </Combobox.Button>
                        </div>
                        <Transition
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                            afterLeave={() => setQuery('')}
                        >
                            <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {filteredSpace.length === 0 && query !== '' ? (
                                <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                Nothing found.
                                </div>
                            ) : (
                                filteredSpace.map((sala) => (
                                <Combobox.Option
                                    key={sala.id}
                                    className={({ active }) =>
                                    `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                        active ? 'bg-esact-600 text-white' : 'text-gray-900'
                                    }`
                                    }
                                    value={sala}
                                >
                                    {({ selected, active }) => (
                                    <>
                                        <span
                                        className={`block truncate ${
                                            selected ? 'font-medium' : 'font-normal'
                                        }`}
                                        >
                                        {sala.codSala}
                                        </span>
                                        {selected ? (
                                        <span
                                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                            active ? 'text-white' : 'text-esact-600'
                                            }`}
                                        >
                                            <AiOutlineCheckCircle className="h-5 w-5" aria-hidden="true" />
                                        </span>
                                        ) : null}
                                    </>
                                    )}
                                </Combobox.Option>
                                ))
                            )}
                            </Combobox.Options>
                        </Transition>
                        </div>
                    </Combobox>
                </div>
                <div className='mb-3'>  
                    <input type='checkbox' id='isVisible' name='isVisible' checked={isVisible} onChange={onChange} className='border border-solid border-gray-600 rounded-sm mr-2' />
                    <label>Visível</label>       
                </div>
                <div className='mb-3'>  
                    <input type='checkbox' id='hasClasses' name='hasClasses' checked={hasClasses} onChange={onChange} className='border border-solid border-gray-600 rounded-sm mr-2' />
                    <label>Tem aulas</label>       
                </div>
                <div className='mb-3'>
                    <button type='submit' className='w-full mb-5 py-3 px-5 border border-solid border-esact-600 rounded-md bg-esact-600 text-white text-base font-bold cursor-pointer text-center flex items-center justify-center'>Submeter</button>
                </div>
            </form>
        </section>
        </>
    )
}

export default FormAddSpace