//Modules
import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'

//components
import Spinner from '../../components/Spinner'

//Utils
import { register } from '../../features/auth/authSlice'

function FormAddUser({ closeModal, setUsers }) {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    password2: '',
    isAdmin: false,
    isManager: false,
    isActive: true,
    office: '',
    phone: '',
    role: ''
  })

  const { name, email, password, password2, isAdmin, isManager, isActive, office, phone, role } = formData

  const dispatch = useDispatch()

  const { isLoading } = useSelector((state) => state.auth)

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.name === ('isActive') ? e.target.checked : e.target.value
    }))
  }

  const onSubmit = (e) => {
    e.preventDefault()

    if(password !== password2){
      toast.error('As passwords não coincidem.')
    }else{
      const userData = {
        name,
        email,
        password,
        isAdmin,
        isManager,
        isActive,
        office,
        phone,
        role: role.split(';')
      }

      const result = dispatch(register(userData))
      result.then((data) => {
        if(data.error){
          toast.error(data.payload)
        }else{
          toast.success(data.payload.message)
          setFormData({
            name: '',
            email: '',
            password: '',
            password2: '',
            isAdmin: false,
            isManager: false,
            isActive: true,
            office: '',
            phone: '',
            role: ''
          })          
          setUsers((prevState) => ([...prevState, data.payload.userCreated]))
          closeModal()
        }
      })
    }
  }

  if(isLoading){
    return <Spinner />
  }

  return (
    <section className='w-9/12 mx-auto'>
    <form onSubmit={onSubmit}>
        <div className='mb-3'>            
        <input type='text' id='name' name='name' value={name} placeholder='Escreva o nome' onChange={onChange} className='w-full p-3 rounded-lg shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-esact-600 mb-3' />
        </div>
        <div className='mb-3'>            
        <input type='email' id='email' name='email' value={email} placeholder='Escreva o email' onChange={onChange} className='w-full p-3 rounded-lg shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-esact-600 mb-3' />
        </div>
        <div className='mb-3'>            
        <input type='text' id='office' name='office' value={office} placeholder='Indique o gabinete' onChange={onChange} className='w-full p-3 rounded-lg shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-esact-600 mb-3' />
        </div>
        <div className='mb-3'>            
        <input type='text' id='phone' name='phone' value={phone} placeholder='Indique a extensão' onChange={onChange} className='w-full p-3 rounded-lg shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-esact-600 mb-3' />
        </div>
        <div className='mb-3'>            
        <input type='password' id='password' name='password' value={password} placeholder='Escreva a password' onChange={onChange} className='w-full p-3 rounded-lg shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-esact-600 mb-3' />
        </div>
        <div className='mb-3'>            
        <input type='password' id='password2' name='password2' value={password2} placeholder='Confirme a password' onChange={onChange} className='w-full p-3 rounded-lg shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-esact-600 mb-3' />
        </div>
        <div className='mb-3'>            
        <input type='text' id='role' name='role' value={role} placeholder='employee;student;manager;admin;root' onChange={onChange} className='w-full p-3 rounded-lg shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-esact-600 mb-3' />
        </div>
        <div className='mb-3'>  
        <input type='checkbox' id='isActive' name='isActive' checked={isActive} onChange={onChange} className='border border-solid border-gray-600 rounded-sm mr-2' />
        <label>Ativo</label>       
        </div>
        <div className='mb-3'>
        <button type='submit' className='w-full mb-5 py-3 px-5 border border-solid border-esact-600 rounded-md bg-esact-600 text-white text-base font-bold cursor-pointer text-center flex items-center justify-center'>Submeter</button>
        </div>
    </form>
    </section>
  )
}

export default FormAddUser