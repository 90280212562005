//Modules

//Components
import Reservation from './Reservation'

//Utils
import { useWindowWidthContext } from '../../utils/UtilContext'

const ListAllReservations = ({ reservations, user }) => {
    const { windowWidth } = useWindowWidthContext()
    
    return (
        <section className='tableList'>
            <div className='tableListTitle'>Reservas</div>
            <table className='w-full'>
                <thead>
                    <tr>
                        <td>#</td>
                        <td>Sala</td>
                        <td className='text-center'>Dia</td>
                        {windowWidth > 640 && <td className='text-center'>Estado</td>}
                        {user.role.includes("root") && <td className='text-center'>Utilizador</td>}
                        {windowWidth > 640 && <td className='text-center'>Criada em</td>}                    
                        {windowWidth > 640 && <td className='text-center'>Última alteração</td>}                    
                    </tr>
                </thead> 
                <tbody>
                    {reservations.length === 0 ?
                        <tr className='text-gray-600'>
                            <td colSpan={(windowWidth > 640 && user.role.includes("root")) ? 7 : windowWidth > 640 ? 6 : 3}>Não tem reservas registadas.</td>    
                        </tr>
                    :reservations.map((reservationInfo, index) => (
                        <Reservation key={reservationInfo._id} reservationInfo={reservationInfo} windowWidth={windowWidth} user={user} index={index} />
                        ))
                    }
                    
                </tbody>
            </table>      
        </section>
    )
}

export default ListAllReservations