//Modules
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import ConfirmDialogProvider from 'react-confirm-window'

//Components
import { store } from './app/store';
import App from './App';

//Utils
import { WindowWidthContextProvider } from './utils/UtilContext'

//Assets
import 'tw-elements';
import 'react-tooltip/dist/react-tooltip.css';
import './index.css';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  // <React.StrictMode>
    <Provider store={store}>
      <WindowWidthContextProvider>
        <ConfirmDialogProvider>
          <App />
        </ConfirmDialogProvider>
      </WindowWidthContextProvider>
    </Provider>
  // </React.StrictMode>
);