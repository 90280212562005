//Modules
import { useContext, useEffect, useState } from 'react'
import { createContext } from "react";

export const windowWidthContext = createContext()

export const WindowWidthContextProvider = ({ children }) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)

    useEffect(() =>{
        const handleResize = () => setWindowWidth(window.innerWidth)
        window.addEventListener('resize', handleResize)

        return () => window.removeEventListener('resize', handleResize)
    },[])
    
    return(
        <>
            <windowWidthContext.Provider value={{windowWidth}}>
                {children}
            </windowWidthContext.Provider>
        </>
    )
}

export const useWindowWidthContext = () => {
    const context = useContext(windowWidthContext)
    if(!context) {
        throw new Error("useWindowWidthContext must be used within a WindowWidthContextProvider!!!")
    }

    return context
}