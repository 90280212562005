//Module
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

//Components
import managerService from './managerService'

//Variables
const initialState = {
    managerSpaces: [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: ''
}

export const getManagerSpaces = createAsyncThunk('manager/getManagerSpaces', async (user, thunkAPI) => {
    try {
        return await managerService.getManagerSpaces(user, thunkAPI.getState().auth.user)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

export const registerManager = createAsyncThunk('manager/registerManager', async (managerData, thunkAPI) => {
    try {
        return await managerService.registerManager(managerData, thunkAPI.getState().auth.user)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

export const managerSlice = createSlice({
    name: 'manager',
    initialState,
    reducers: {
        managerSpacesReset: (state) =>  {
            state.managerSpaces = []
            state.isLoading = false
            state.isError = false
            state.isSuccess = false
            state.message = ''
        }   
    },
    extraReducers: (builder) => {
        builder
            .addCase(getManagerSpaces.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getManagerSpaces.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
                state.managerSpaces = action.payload
            })
            .addCase(getManagerSpaces.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })        
            .addCase(registerManager.pending, (state) => {
                state.isLoading = true
            })
            .addCase(registerManager.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
            })
            .addCase(registerManager.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })        
    }
})

export const { managerSpacesReset } = managerSlice.actions
export default managerSlice.reducer