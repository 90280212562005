//Modules
import { toast } from 'react-toastify'

//Funçaõ para preencher as ComboBox das horas
export const renderHourOptions = () => {
    const hours = [];
    for (let i = 8; i <= 22; i++) {            
        const hour = i.toString().padStart(2, "0");
        for (let j = 0; j <=30; j += 30){
            const minute = j.toString().padStart(2, "0");
            hours.push(<option key={hour+':'+minute} value={hour+':'+minute}>{hour+':'+minute}</option>);
        }
    }
    hours.push(<option key={'23:00'} value={'23:00'}>{'23:00'}</option>);
    return hours;
}

//BEGIN - Funções para gerir o clique num dia em vista de mês
export const handleMonthDayClick = (info, setSelectedDate, setShowForm) => {
    if(info.view.type === 'dayGridMonth'){      
        const today = new Date()
        if (info.date < today) {
            toast.error('Não pode criar reservas no passado.')
        }else{                
            setSelectedDate(info.dateStr);
            setShowForm(true)
        }
    }
}

export const handleMonthSubmitReservation = (selectedDate, startTime, endTime, formData, space, submitReservation, setSelectedDate, setStartTime, setEndTime, setShowForm, setFormData) => {
    const begin = new Date(selectedDate+' '+startTime)
    const end = new Date(selectedDate+' '+endTime)
    const { description } = formData

    if (begin >= end) {
        toast.warning('A hora inicial tem de ser menor que a final')
    }else{    
        const reservationData = {
            reservation: {
                startStr: `${selectedDate}T${startTime}:00`,
                endStr: `${selectedDate}T${endTime}:00`,
                description: description
            },
            space: space._id
        }  
        submitReservation(reservationData)            
        
        setSelectedDate('')
        setStartTime('08:00')
        setEndTime('08:00')
        setFormData({
            description: ''
        })
        setShowForm(false)
    }
}
//END - Funções para gerir o clique num dia em vista de mês

// Função para cancelar a introduçaõ de uma reserva (vista de mês e semana)
export const handleCancelReservation = (setSelectedDate, setStartTime, setEndTime, setSelectedData, setFormData, setShowFormMonthView, setShowFormWeekView, setShowFormClickEvent) => {
    setSelectedDate('')
    setStartTime('08:00')
    setEndTime('08:00')
    setSelectedData({})
    setFormData({
        description: ''
    })
    setShowFormMonthView(false)
    setShowFormWeekView(false)
    setShowFormClickEvent(false)
}

//BEGIN - Funções para gerir a submissão de uma reserva
export const onChangeFormData = (e, setFormData) => {
    setFormData((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value
      }))
}

export const handleWeekSubmitReservation = (selectedData, formData, space, submitReservation, setShowFormWeekView, setSelectedData, setFormData) => {
    const { description } = formData

    const reservationData = {
        reservation: {...selectedData, description: description},
        space: space._id
    }

    submitReservation(reservationData)
    setSelectedData({})
    setFormData({
        description: ''
    })
    setShowFormWeekView(false)
}

export const handleSubmitUpdateReservation = (e, updateEvent, formData, selectedData, setShowFormClickEvent, setSelectedData, setFormData) => {
    e.preventDefault()

    const { description } = formData

    const reservationData = {
        id: selectedData.event._def.publicId,
        begin: selectedData.event._instance.range.start,
        end: selectedData.event._instance.range.end,
        state: selectedData.event._def.extendedProps.state,
        description: description
    }  

    updateEvent(reservationData,selectedData)
    setSelectedData({})
    setFormData({
        description: ''
    })
    setShowFormClickEvent(false)
}
 //END - Funções para gerir a submissão de uma reserva

 //Função que dispara quando é selecionado um intervalo de horas em vista de semana
 export const handleSelect = (selectInfo, calendarApi, user, setSelectedData, setShowFormWeekView) => {
    const today = new Date()
    calendarApi.getApi().unselect()
    if (selectInfo.start > today || user.role.includes("root")){
        if (user.role.includes("student") && ((selectInfo.end - selectInfo.start) > 7200000)){
            toast.warning('Não pode criar reservas com mais de duas horas.')
        }else{            
            setSelectedData(selectInfo)
            setShowFormWeekView(true)
        }
    }else{
        toast.warning('Não pode criar reservas no passado.')
    }
}

//Função que dispara quando se clica em uma reserva
export const handleEventClick = (event, setFormData, setSelectedData, setShowFormClickEvent, user, isUserManager) => {

    if (event.event._def.extendedProps.state === 3 || (event.event._def.extendedProps.user !== user._id && (!user.role.includes("root") || !user.role.includes("admin")) && !isUserManager)){
        toast.warning('Esta reserva não pode ser modificada por si.')
    }else{        
        setFormData({
            description: event.event._def.extendedProps.description
        })
        setSelectedData(event)
        setShowFormClickEvent(true)
    }
}