//Modules
import { useSelector } from 'react-redux'

//Utils
import { useWindowWidthContext } from '../../utils/UtilContext'

//Components
import DashUserReservations from './DashUserReservation'

function DashListUserReservations() {
    const { windowWidth } = useWindowWidthContext()
    const today = new Date()

    const { userReservations } = useSelector((state) => state.reservation)

    const userFutureReservations = userReservations.filter((reservation) => new Date(reservation.begin) >= today)

    return (
        <section className='tableList'>
            <div className='tableListTitle'>Reservas Futuras</div>
            <table className='w-full'>
                <thead>
                    <tr>
                        <td>#</td>
                        <td>Sala</td>
                        <td className='text-center'>Dia</td>                        
                        {windowWidth > 640 && 
                        <>
                            <td className='text-center'>Início</td>
                            <td className='text-center'>Fim</td>
                        </>}
                        <td className='text-center'>Estado</td>
                        <td></td>
                    </tr>
                </thead>
                <tbody>
                {userFutureReservations.length === 0 ?
                <tr className='text-gray-600'><td colSpan='3'>Não tem reservas futuras.</td></tr> :
                userFutureReservations.map((userFuteureReservation, index) => (                    
                    <DashUserReservations key={userFuteureReservation._id} userReservation={userFuteureReservation} windowWidth={windowWidth} index={index} />                    
                ))}
                </tbody>
            </table>      
        </section>
    )
}

export default DashListUserReservations