//Modules
import axios from 'axios'

//Utils
import authService from '../auth/authService'

//Variables
const API_URL = '/api/managers/'

const registerManager = async (managerData, userData) => {
    const config = {
        headers: {
            Authorization: `Bearer ${userData.token}`
        }
    }

    const response = await axios.post(API_URL, managerData, config)

    return response.data
}


const getManagerSpaces = async (userData, user) => {
    const config = {
        headers: {
            Authorization: `Bearer ${user.token}`
        }
    }
    const response = await axios.get(`${API_URL}spaces/${userData._id}`, config)

    return response.data
}

const getSpacesManagers = async (spaceID, userData) => {
    const config = {
        headers: {
            Authorization: `Bearer ${userData.token}`
        }
    }
    const response = await axios.get(`${API_URL}${spaceID}`, config)

    const managersDataServer = response.data.map(async (manager) => {
        const managerData = await authService.getUser(manager.user_id,userData)
        return managerData
    })                  
    const managersData = await Promise.all(managersDataServer) 

    return managersData
}

const deleteManagerSpaces = async (data, userData) => {
    const config = {
        headers: {
            Authorization: `Bearer ${userData.token}`
        }
    }

    const response = await axios.delete(`${API_URL}${data.user_id}/${data.space_id}`, config)

    return response.data
}

const managerService = {
    getManagerSpaces,
    getSpacesManagers,
    deleteManagerSpaces,
    registerManager
}

export default managerService