//Modules
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { MdDelete } from 'react-icons/md'
import { FiCheckCircle } from 'react-icons/fi'
import { TiDeleteOutline } from 'react-icons/ti'
import { useConfirm } from 'react-confirm-window'

//Utils
import managerService from '../../features/managers/managerService'
import { updateUser } from '../../features/auth/authSlice'


const ManagerSpace = ({ manager, windowWidth, spaceId, user, index, setSpaceManagers, managers}) => {
    const {name, email, phone, office, isActive} = manager

    const dispatch = useDispatch()
    const confirm = useConfirm()

    const onDelete = async () => {
        const choice = await confirm({
            title: "Tem a certeza que deseja apagar o gestor?",
            closeButtonLable: "Não",
            confirmButtonLable: "Sim",
        })
        if (choice) {
            const data = {
                user_id: manager._id,
                space_id: spaceId
            }   
            
            const result = await managerService.deleteManagerSpaces(data, user)     
            
            const checkManager = await managerService.getManagerSpaces(manager, user)
            if(checkManager.length === 0){
                let dataToUpdate = []
                dataToUpdate.push({role: manager.role.filter((role) => role !== "manager")}, manager)
                
                dispatch(updateUser(dataToUpdate, user))
            }

            const newDataDeletedManager =  managers.filter((manager) => manager._id !== result.deletedManager.user_id)
            setSpaceManagers(newDataDeletedManager)

            toast.success(result.message)          
        }
    }

    return (
        <tr className='text-gray-600'>
            <td>{index+1}</td>
            <td></td>
            {windowWidth > 640 && <td>{name}</td>}
            <td>{email}</td>
            <td className='text-center'>{phone}</td>
            {windowWidth > 640 && <td className='text-center'>{office}</td>}                    
            {windowWidth > 640 && <td className='flex justify-center'>{isActive ? <FiCheckCircle className='text-lg text-esact-900' /> : <TiDeleteOutline className='text-2xl text-rejected' />}</td>}
            <td className='text-center'><MdDelete onClick={onDelete} className='text-xl cursor-pointer text-rejected' /></td>
        </tr>
    )
}

export default ManagerSpace