import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../features/auth/authSlice'
import spaceReducer from '../features/spaces/spaceSlice'
import reservationReducer from '../features/reservations/reservationSlice'
import managerReducer from '../features/managers/managerSlice'


export const store = configureStore({
  reducer: {
    auth: authReducer,
    space: spaceReducer,
    reservation: reservationReducer,
    manager: managerReducer
  },
});
