//Modules
import axios from 'axios'

//Variables
const API_URL = '/api/users/'
const { REACT_APP_FOTOS_API } = process.env

const getPhoto = async (token, userData) => {
    let url = ''
    const config = {
        headers: {
            Authorization: `Basic ${token}`
        }
    }

    const resPhoto = await fetch(`${REACT_APP_FOTOS_API}${userData.email.split('@')[0]}`, config)

    if (resPhoto.ok){                
        const blobPhoto = await resPhoto.blob()
        url = URL.createObjectURL(blobPhoto)
    }

    return url
}

const register = async (userData, user) => {
    const config = {
        headers: {
            Authorization: `Bearer ${user.token}`
        }
    }

    const response = await axios.post(API_URL, userData, config)

    return response.data
}

const login = async (userData) => {
    let userLogin = {}
    const response = await axios.post(API_URL+'login', userData)
    
    if(response.data){
        const photoToken = btoa(`${userData.email.split('@')[0]}:${userData.password}`)

        userLogin = {...response.data, photoToken}            
        
        localStorage.setItem('user',JSON.stringify(userLogin))  
    }
    return userLogin
}

const updateUser = async (userData,user) => {
    const config = {
        headers: {
            Authorization: `Bearer ${user.token}`
        }
    }

    const response = await axios.put(`${API_URL}${userData[1]._id}`, userData[0], config)    

    if (userData[1]._id === user._id){
        if (response.data) {
            const newUserData = {...userData[1],...userData[0]}
            localStorage.setItem('user',JSON.stringify(newUserData))
            return newUserData
        }else{
            return user
        }
    }else{
        return user
    }

}

const logout = async () => {
    localStorage.removeItem('user')
}

const getUser = async (userId, userData) => {
    const config = {
        headers: {
            Authorization: `Bearer ${userData.token}`
        }
    }
    const response = await axios.get(`${API_URL}${userId}`, config)    

    return response.data
}

const getUsers = async (userData) => {
    const config = {
        headers: {
            Authorization: `Bearer ${userData.token}`
        }
    }

    const response = await axios.get(`${API_URL}`, config)    
    
    return response.data

}

const authService = {
    register,
    logout,
    login,
    getUser,
    getUsers,
    updateUser,
    getPhoto
}

export default authService
