//Modules
import { useState } from 'react'
import { Switch } from '@headlessui/react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'

//Utils
import { updateUser } from '../../features/auth/authSlice'

const User = ({ userInfo, windowWidth, user, index }) => {
    const [enabled, setEnabled] = useState(userInfo.isActive)
    const dispatch = useDispatch()

    const changeActive = (val) => {
        setEnabled(val)

        let dataToUpdate = []
        dataToUpdate.push({isActive: val}, userInfo)

        const active = dispatch(updateUser(dataToUpdate, user))
            active.then((t) => {
                toast.success(val === true ? 'Utilizador ativado' : 'Utilizador desativado')
            })
    }

    return (
        <tr className='text-gray-600'>
            <td>{index+1}</td>
            <td>{userInfo.name}</td>
            {windowWidth > 640 && <td>{userInfo.email}</td>}
            <td className='text-center'>{userInfo.phone}</td>
            {windowWidth > 640 && <td className='text-center'>{userInfo.office}</td>}             
            <td className='text-center'>
                <Switch
                checked={enabled}
                onChange={changeActive}
                className={`${enabled ? 'bg-aproved' : 'bg-rejected'}
                relative inline-flex h-[30px] w-[67px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
                <span className="sr-only">Use setting</span>
                <span
                aria-hidden="true"
                className={`${enabled ? 'translate-x-9' : 'translate-x-0'}
                    pointer-events-none inline-block h-[26px] w-[26px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                />
            </Switch>
            </td>
        </tr>
    )
}

export default User