//Modules
import { useState, useEffect, Fragment } from 'react'
import { useSelector } from 'react-redux'
import { Dialog, Transition } from '@headlessui/react'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { toast } from 'react-toastify'

//Components
import DashListSpaces from '../components/spaces/DashListSpaces'
import FormAddSpace from '../components/spaces/FormAddSpace'
import Spinner from '../components/Spinner'

//Util
import { useLogout, isSessionActive } from '../utils/UtilFunctions'

const SpacesList = () => {
  let [isOpen, setIsOpen] = useState(false)

  const { user } = useSelector((state) => state.auth)

  const onLogout = useLogout()
  const active = isSessionActive()

  useEffect(() => {
    if(!active.sessionState || !user.role.includes("root")){
      onLogout.out()
      toast.warning(active.message)
    }        
}, [user])

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }

  //LOADING
  if (!user) {
    return <Spinner />
}

  return (
    <section className='px-5 md:px-0'>
        <section className='container  my-[40px]'>
            <h2 className='text-center text-3xl text-slate-600 font-bold mb-4'>SALAS</h2>
        </section>

        <DashListSpaces />

        <div className="mt-5">
          <button
            type="button"
            onClick={openModal}
            className="rounded-md bg-esact-600 px-4 py-2 text-md font-medium text-white focus:outline-none"
          >
            Adicionar
          </button>
        </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="realtive text-lg font-medium leading-6 text-gray-900 uppercase text-center mb-10"
                  >
                    Adicionar Sala
                    <span onClick={closeModal} className='absolute text-2xl cursor-pointer text-gray-500 top-2 right-2'><AiOutlineCloseCircle /></span>
                  </Dialog.Title>                  
                  <FormAddSpace closeModal={closeModal} />                  
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </section>
  )
}

export default SpacesList