//Modules
import axios from 'axios'

//Variables
const API_URL = '/api/email/'

const sendEmail = async (emailData, userData) => {
    const config = {
        headers: {
            Authorization: `Bearer ${userData.token}`
        }        
    }

    const email = {
        email: emailData.email,
        subject: emailData.subject,
        message: emailData.message
    }

    const response = await axios.post(`${API_URL}`, email, config)

    return response.data
}

const emailService = {
    sendEmail
}

export default emailService