//Modules
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'

//Utils
import { useWindowWidthContext } from '../../utils/UtilContext'

//Components
import Spinner from '../Spinner'
import SpaceManager from './SpaceManager'


function ListSpaceManager() {
  const { managerSpaces, isLoading, isError, message } = useSelector((state) => state.manager)
  const { windowWidth } = useWindowWidthContext()

  const { pathname } = useLocation()

  useEffect(() => {     
    
    if(isError){
      toast.error(message)
    }

  }, [])

  if (isLoading) {
    return <Spinner />
  }

  return (    
    <section className='tableList'>
      {pathname === '/profile' ? <div className='profileTitle flex justify-between'><span>Gestor</span></div>
      :<div className='tableListTitle'>Salas</div>}
      <table className='w-full'>
        <thead>
          <tr>
            <td>#</td>
            <td>Sala</td>
            {windowWidth > 640 && <td className='text-center'>Capacidade</td>}
            <td></td>
          </tr>
        </thead>
        <tbody>
          {managerSpaces.map((managerSpace, index) => (
            <SpaceManager key={managerSpace._id} managerSpace={managerSpace} windowWidth={windowWidth} index={index} />
          ))}
        </tbody>
      </table>      
    </section>
  )
}

export default ListSpaceManager