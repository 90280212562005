//Modules
import { useEffect, useState} from 'react'
import { Link } from 'react-router-dom'
import { formatDate } from '@fullcalendar/core'
import { Tooltip } from 'react-tooltip';

//Utils
import spaceService from '../../features/spaces/spaceService'
import authService from '../../features/auth/authService'

//Variables
const reservationState = ['Cancelada', 'Pendente', 'Aprovada']
const reservationColor = ['text-rejected', 'text-pending', 'text-aproved']

const Reservation = ({ reservationInfo, windowWidth, user, index }) => {
    const [spaceInfo, setSpaceInfo] = useState({})
    const [userInfo, setUserInfo] = useState({})
    
    const getSpaceInfo = async () => {
        const data = await spaceService.getSpace(reservationInfo.space_id, user)
        setSpaceInfo(data)
    }

    const getUserInfo = async () => {
        const data = await authService.getUser(reservationInfo.user_id, user)
        setUserInfo(data)
    }

    useEffect(() => {
        getSpaceInfo()

        if(user.role.includes("root")){
            getUserInfo()
        }else{
            setUserInfo(user)
        }
    },[])
    
    return (
        <tr className='text-gray-600'>
            <td>{index+1}</td>
            <td>
                <Link data-tooltip-id={reservationInfo._id} to={`/space/${spaceInfo._id}/${reservationInfo.begin.slice(0,10)}`} className='cursor-pointer underline'>{spaceInfo.name}</Link>
                {'description' in reservationInfo &&
                <Tooltip id={reservationInfo._id}>
                    <div>                    
                        <p>{reservationInfo.description}</p>
                    </div>
                </Tooltip>
                }    
            </td>
            <td className='text-center'>{formatDate(reservationInfo.begin, {day:'2-digit', month: '2-digit', year: 'numeric', locale: 'pt'})}</td>
            {(!user.role.includes("root") || windowWidth > 640) && <td className={`text-center ${reservationColor[reservationInfo.state]}`}>{reservationState[reservationInfo.state]}</td>}
            {user.role.includes("root") && <td className='text-center'>{userInfo.name}</td>}
            {windowWidth > 640 && <td className='text-center'>{formatDate(reservationInfo.createdAt, {day:'2-digit', month: '2-digit', year: 'numeric', hour:'2-digit', minute: '2-digit', timeZone: 'Europe/lisbon', locale: 'pt'})}</td>}
            {windowWidth > 640 && <td className='text-center'>{formatDate(reservationInfo.updatedAt, {day:'2-digit', month: '2-digit', year: 'numeric', hour:'2-digit', minute: '2-digit', timeZone: 'Europe/lisbon', locale: 'pt'})}</td>}
        </tr>
    )
}

export default Reservation