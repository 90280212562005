//Modules
import { useState, useEffect, Fragment } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { Combobox, Transition } from '@headlessui/react'
import { AiOutlineCheckCircle } from 'react-icons/ai'
import { HiOutlineSelector } from 'react-icons/hi'

//components
import Spinner from '../../components/Spinner'

//Utils
import { updateUser } from '../../features/auth/authSlice'
import { registerManager } from '../../features/managers/managerSlice'
import { getUser } from '../../features/auth/authSlice'
import authService from '../../features/auth/authService'

const FormAddManager = ({ space_id, closeModal, managers, setSpaceManagers }) => {
    const [addManagerFields, setAddManagerFields] = useState({
        user_id: '',
    })
    const [users, setUsers] = useState([])
    const [selectedUser, setSelectedUser] = useState({})
    const [query, setQuery] = useState('')

    const { user_id } = addManagerFields

    const dispatch = useDispatch()

    const { user } = useSelector((state) => state.auth)

    const getUsersInfo = async () => {
        const data = await authService.getUsers(user)
        const usersFiltered = data.filter((user) => {
            const filter = managers.map((manager) => {
                let count = 0
                if(user._id === manager._id){
                    count ++
                }
                return count        
            })
            return (!filter.includes(1) && user.isActive)
        })
        setUsers(usersFiltered)
    }
    
    useEffect(() => {
        getUsersInfo()
    }, [])

    const onChange = (e) => {
        if ('name' in e){            
            setSelectedUser(e)
            setAddManagerFields((prevState) => ({
                ...prevState,
                user_id: e._id
            }))
        }
    }

    const onSubmit = (e) => {
        e.preventDefault()
        
        const managerData = {
            user_id,
            space_id
        }

        const result = dispatch(registerManager(managerData))
        result.then((data) => {
            if(data.error){
                toast.error(data.payload)
            }else{
                const resultNewManager = dispatch(getUser(data.payload.manager.user_id))
                resultNewManager.then((data) => {
                    if(data.error){
                        toast.error(data.payload)
                    }else{
                        setSpaceManagers((prevState) => ([...prevState, data.payload]))
                        if(!data.payload.role.includes("manager")){
                            let dataToUpdate = []
                            selectedUser.role.push("manager")
                            dataToUpdate.push({role: selectedUser.role}, data.payload)
                            
                            dispatch(updateUser(dataToUpdate, user))
                        }
                    }
                })
                toast.success(data.payload.message)
                setAddManagerFields({
                    user_id: ''
                })
                setSelectedUser({})
                closeModal()
            }
        })
    }

    const filteredUser =
    query === ''
      ? users
      : users.filter((user) => {
          return user.name.toLowerCase().includes(query.toLowerCase())
        })
    
    if(users.length === 0){
        return <Spinner />
      }

    return (
        <>
        <section className='w-full mx-auto'>
            <form onSubmit={onSubmit}>               
                <div className='mb-5'>     
                    <label>Utilizador:</label>      
                    <Combobox name='user_id' value={selectedUser} onChange={onChange}>
                        <div className="relative mt-1">
                        <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-esact-600 sm:text-sm">
                            <Combobox.Input
                            className="w-full border-none p-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                            displayValue={(user) => user.name}
                            onChange={(event) => setQuery(event.target.value)}
                            />
                            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                                <HiOutlineSelector
                                    className="h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                />
                            </Combobox.Button>
                        </div>
                        <Transition
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                            afterLeave={() => setQuery('')}
                        >
                            <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {filteredUser.length === 0 && query !== '' ? (
                                <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                Nothing found.
                                </div>
                            ) : (
                                filteredUser.map((user) => (
                                <Combobox.Option
                                    key={user._id}
                                    className={({ active }) =>
                                    `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                        active ? 'bg-esact-600 text-white' : 'text-gray-900'
                                    }`
                                    }
                                    value={user}
                                >
                                    {({ selected, active }) => (
                                    <>
                                        <span
                                        className={`block truncate ${
                                            selected ? 'font-medium' : 'font-normal'
                                        }`}
                                        >
                                        {user.name}
                                        </span>
                                        {selected ? (
                                        <span
                                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                            active ? 'text-white' : 'text-esact-600'
                                            }`}
                                        >
                                            <AiOutlineCheckCircle className="h-5 w-5" aria-hidden="true" />
                                        </span>
                                        ) : null}
                                    </>
                                    )}
                                </Combobox.Option>
                                ))
                            )}
                            </Combobox.Options>
                        </Transition>
                        </div>
                    </Combobox>
                </div>                
                <div className='mb-3'>
                    <button type='submit' className='w-full mb-5 py-3 px-5 border border-solid border-esact-600 rounded-md bg-esact-600 text-white text-base font-bold cursor-pointer text-center flex items-center justify-center'>Submeter</button>
                </div>
            </form>
        </section>
        </>
    )
}

export default FormAddManager