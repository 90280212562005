//Modules
import React, {useEffect, useState} from 'react'
import { useSelector } from 'react-redux'

//Utils
import reservationService from '../../features/reservations/reservationService'

//Components
import Spinner from '../Spinner'
import DashReservationManagerPending from './DashReservationManagerPending'

function DashReservationManager({ managerSpace }) {
    const [spaceManagerReservations, setSpaceManagerReservations] = useState([])  
    const today = new Date()  
  
    const { user } = useSelector((state) => state.auth)
    
    const getReservationData = async () => {
        const reservationInfo = await reservationService.getSpaceReservations({spaceId: managerSpace.space_id, start: today, state: 1},user)
        setSpaceManagerReservations(reservationInfo)
    }

    useEffect(() => {           
        getReservationData()
      }, [])
    
      if (!spaceManagerReservations) {
        return <tr><td><Spinner /></td></tr>
      }

    return (
        <>
            {spaceManagerReservations.length === 0 ?
            <tr className='text-gray-600'><td colSpan='3'>Não existem reservas pendentes...</td></tr> :
            spaceManagerReservations.map((reservation, index) => (
                <DashReservationManagerPending key={reservation._id} reservation={reservation} index={index} />
            ))}        
        </>
    )
}

export default DashReservationManager