//Modules
import { useRef } from 'react'
import { toast } from 'react-toastify'

//Utils
import { renderHourOptions } from '../../utils/UtilSpace'

const FormEventClickMonth = ({ selectedDate, handleMonthSubmitReservation, startTime, setStartTime, endTime, setEndTime, formData, onChangeFormData, handleCancelReservation }) => {
    const { description } = formData
    const descriptionRef = useRef(null)

    const onSubmitData = (e) => {
        e.preventDefault()

        if (!descriptionRef.current.value){
            toast.warning('Por favor preencha todos os campos.')
        }else{
            handleMonthSubmitReservation()
        }
    }

    return (
        <section>
            <div className="fixed top-0 left-0 w-screen h-screen z-[999] bg-slate-600 opacity-60"></div>
            <div className="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[90%] md:w-3/4 lg:w-1/4 bg-white p-5 rounded-lg shadow-md z-[9999]">
            <h2 className='text-slate-600 mb-5 text-2xl text-center'>Reserva para {selectedDate}</h2>
                <form onSubmit={onSubmitData}>
                <div className="flex flex-col items-center">
                    <label htmlFor="startTime" className='mb-2'>Hora de início:</label>
                    <select
                        id="startTime"
                        value={startTime}
                        onChange={(e) => setStartTime(e.target.value)}
                        className="w-1/4 px-3 py-2 mb-5 bg-slate-200 rounded"
                    >
                        {renderHourOptions()}
                    </select>

                    <label htmlFor="endTime" className='mb-2'>Hora de fim:</label>
                    <select
                        id="endTime"
                        value={endTime}
                        onChange={(e) => setEndTime(e.target.value)}
                        className="w-1/4 px-3 py-2 mb-5 bg-slate-200 rounded"
                    >
                        {renderHourOptions()}
                    </select>
                    <div className='flex flex-col md:flex-row gap-3 items-center px-3 py-2 w-full'> 
                        <label htmlFor="description" className='mb-2'>Descrição:</label>
                        <input type='text' id='description' ref={descriptionRef} name='description' value={description} placeholder='Motivo da reserva' onChange={onChangeFormData} className='w-full p-2 mb-3 border border-solid border-gray-400 rounded-md' />
                    </div>
                </div>
                    <br />
                    <div className='flex flex-col md:flex-row mx-5 mt-6 justify-center gap-6'>                            
                        <button className='bg-esact-600 text-white border-none rounded-md px-5 py-2 text-sm cursor-pointer hover:bg-esact-900' type="submit">Guardar</button>
                        <button className='bg-esact-600 text-white border-none rounded-md px-5 py-2 text-sm cursor-pointer hover:bg-esact-900' type="button" onClick={handleCancelReservation}>Cancelar</button>
                    </div>
                </form>
            </div>
        </section>
    )
}

export default FormEventClickMonth