//Modules
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'

//Utils
import { useWindowWidthContext } from '../../utils/UtilContext'

//Components
import DashSpace from './DashSpace'

function DashListSpaces() {
  const { spaces } = useSelector((state) => state.space)
  const { user } = useSelector((state) => state.auth)
  const { windowWidth } = useWindowWidthContext()
  const { pathname } = useLocation()

  return (
    <section className='tableList'>
            <div className='tableListTitle'>Salas Disponíveis</div>
            <table className='w-full'>
                <thead>
                    <tr>
                        <td>#</td>
                        <td>Sala</td>
                        {windowWidth > 640 && <td className='text-center'>Capacidade</td>}
                        {(windowWidth > 640 || pathname !== '/spaces') && <td className='text-center'>{(pathname === '/spaces' && user.role.includes("root")) ? "Reservas" : "Reservar"}</td>}
                        {pathname === '/spaces' && user.role.includes("root") && 
                            <>
                                <td className='text-center'></td>
                                <td className='text-center'>Visível</td>
                            </>}
                    </tr>
                </thead>
                <tbody>
                {spaces.map((space, index) => (
                    <DashSpace key={space._id} space={space} windowWidth={windowWidth} pathname={pathname} user={user} index={index} />
                ))}
                </tbody>
            </table>      
        </section>
  )
}

export default DashListSpaces