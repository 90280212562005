//Modules
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { FaEye } from 'react-icons/fa'

//Utils
import spaceService from '../../features/spaces/spaceService'

//Components
import Spinner from '../Spinner'

function SpaceManager({ managerSpace, windowWidth, index }) {
    const [space, setSpace] = useState()
    const { user } = useSelector((state) => state.auth)
    
    const getSpace = async () => {
        const spaceInfo = await spaceService.getSpace(managerSpace.space_id,user)
        setSpace(spaceInfo)
    }

    useEffect(() => {           
        getSpace()
      }, [])
    
      if (!space) {
        return <tr><td><Spinner /></td></tr>
      }
      
    return (
        <>
            {space.isVisible &&
                <tr className='text-gray-600'>
                    <td>{index+1}</td>
                    <td>{space.name}</td>
                    {windowWidth > 640 && <td className='text-center'>{space.capacity === 0 ? "n/d" : space.capacity}</td>}
                    <td className='text-center'><Link to={`/space/${space._id}`}><FaEye className='text-esact-600 text-2xl' /></Link></td>
                </tr>}
        </>
    )
}

export default SpaceManager