//Modules
import { useSelector } from 'react-redux'

//Components
import User from './User'

//Utils
import { useWindowWidthContext } from '../../utils/UtilContext'

const ListUsers = ({ users }) => {
    const { windowWidth } = useWindowWidthContext()

    const { user } = useSelector((state) => state.auth)

    return (
        <section className='tableList'>
            <div className='tableListTitle'>Utilizadores</div>
            <table className='w-full'>
                <thead>
                    <tr>
                        <td>#</td>
                        <td>Nome</td>
                        {windowWidth > 640 && <td>Email</td>}
                        <td className='text-center'>Extensão</td>
                        {windowWidth > 640 && <td className='text-center'>Gabinete</td>}                    
                        <td className='text-center'>Ativo</td>
                    </tr>
                </thead> 
                <tbody>
                    {users.map((userInfo, index) => (
                        <User key={userInfo._id} userInfo={userInfo} windowWidth={windowWidth} user={user} index={index} />
                    ))}
                </tbody>
            </table>      
        </section>
    )
}

export default ListUsers