//Modules
import { useSelector } from 'react-redux'

//Utils
import { useWindowWidthContext } from '../../utils/UtilContext'

//Components
import DashWeekAdmin from "./DashWeekAdmin"
import Spinner from '../Spinner'

const DashListWeekAdmin = () => {
    const { spaces, isLoading } = useSelector((state) => state.space)
    const { windowWidth } = useWindowWidthContext()
    
    if (isLoading) {
        return <Spinner />
    }
    
    return (
        <section className='tableList'>
            <div className='tableListTitle'>Reservas da Semana</div>
            <table className='w-full'>
                <thead>
                    <tr>
                        <td>#</td>
                        <td>Sala</td>
                        {windowWidth > 640 && <td>Utilizador</td>}
                        <td className='text-center'>Dia</td>                        
                        {windowWidth > 640 && 
                        <>
                            <td className='text-center'>Início</td>
                            <td className='text-center'>Fim</td>
                        </>}
                        <td></td>
                    </tr>
                </thead>
                <tbody>
                {spaces.map((space) => (                    
                    <DashWeekAdmin key={space._id} space={space} />
                ))}
                </tbody>
            </table>      
        </section>
    )
}

export default DashListWeekAdmin